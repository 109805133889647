import React from "react";
import { useInView } from "react-intersection-observer";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import AppAppBar from "../../components/AppAppBar";
import Hero from "../../components/Hero";
import Highlights from "../../components/Highlights";
import Features from "../../components/Features";
import Testimonials from "../../components/Testimonials";
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import getLPTheme from "../../styles/getLPTheme";

// Animação para Features (fade up)
const FadeUpBox = styled(Box)(({ inview }) => ({
  opacity: inview ? 1 : 0,
  transform: inview ? "translateY(0)" : "translateY(30px)",
  transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
}));

// Animação para Highlights (fade right)
const FadeRightBox = styled(Box)(({ inview }) => ({
  opacity: inview ? 1 : 0,
  transform: inview ? "translateX(0)" : "translateX(-30px)",
  transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
}));

// Animação para Testimonials (zoom in)
const ZoomInBox = styled(Box)(({ inview }) => ({
  opacity: inview ? 1 : 0,
  transform: inview ? "scale(1)" : "scale(0.8)",
  transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
}));

// Animação para FAQ (fade left)
const FadeLeftBox = styled(Box)(({ inview }) => ({
  opacity: inview ? 1 : 0,
  transform: inview ? "translateX(0)" : "translateX(30px)",
  transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
}));


export default function LandingPage() {
  const LPtheme = createTheme(getLPTheme("dark"));

  // Hooks para observar se os elementos estão na viewport
  const { ref: featuresRef, inView: featuresInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: highlightsRef, inView: highlightsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: testimonialsRef, inView: testimonialsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: faqRef, inView: faqInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar />
      <Hero />
      <Box sx={{ bgcolor: "background.default", overflow: "hidden" }}>
        <FadeUpBox
          ref={featuresRef}
          inview={featuresInView ? 1 : 0}
          sx={{ marginBottom: 2 }}
        >
          <Features />
        </FadeUpBox>
        <Divider />
        <FadeRightBox
          ref={highlightsRef}
          inview={highlightsInView ? 1 : 0}
          sx={{ marginBottom: 2 }}
        >
          <Highlights />
        </FadeRightBox>
        <Divider />
        <ZoomInBox
          ref={testimonialsRef}
          inview={testimonialsInView ? 1 : 0}
          sx={{ marginBottom: 2 }}
        >
          <Testimonials />
        </ZoomInBox>
        <Divider />
        <FadeLeftBox
          ref={faqRef}
          inview={faqInView ? 1 : 0}
          sx={{ marginBottom: 2 }}
        >
          <FAQ />
        </FadeLeftBox>
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
